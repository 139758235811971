import helperService from './helper.service'
import APIError from '../models/api/api.error'

const BASE_URL = process.env.VUE_APP_API_BASE_URL
const API_VERSION = process.env.VUE_APP_API_VERSION

export default {
  executeAPI: async (url, method, body, headers) => {
    url = BASE_URL + API_VERSION + url
    return fetch(url, {
      method: method,
      headers: headers,
      timeut: 60,
      body: body ? JSON.stringify(body) : undefined,
    }).then(response => {
      return helperService.getHttpErrror(response).then(responseError => {
        if (responseError) {
          throw new APIError(responseError.Message, responseError)
        } else {
          return response.json()
        }
      })
    }).then(responseJson => {
      return responseJson
    }).catch(error => {
      console.log(error);
      return error
    })
  },
  executeUploadAPI: async (url, method, formData, headers) => {
    url = BASE_URL + API_VERSION + url
    return fetch(url, {
      method: method,
      headers: headers,
      body: formData,
      timeut: 320,
    }).then(response => {
      return helperService.getHttpErrror(response).then(responseError => {
        if (responseError) {
          throw new APIError(responseError.Message, responseError)
        } else {
          return response.json()
        }
      })
    }).then(responseJson => {
      return responseJson
    }).catch(error => {
      console.log(error);
      return error
    })
  },
  executeExternal: async (url, method, headers) => {
    return fetch(url, {
      method: method,
      headers: headers,
      timeut: 60,
    }).then(response => {
      return helperService.getHttpErrror(response).then(responseError => {
        if (responseError) {
          throw new APIError(responseError.Message, responseError)
        } else {
          return response.json()
        }
      })
    }).then(responseJson => {
      return responseJson
    }).catch(error => {
      throw error
    })
  },
  executeDownloadBlob: async (url, method, headers) => {
    url = BASE_URL + API_VERSION + url

    return fetch(url, {
      method: method,
      headers: headers,
      timeut: 120,
    })
    .then(response => response.arrayBuffer())  // Transformar em ArrayBuffer
    .then(buffer => {
      return buffer;
    })
    .catch(error => {
      console.error('Erro ao carregar o PDF:', error);
    });

  },

}
